/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				$(".dropdown").hover(
				            function() {
				                $('.dropdown-menu', this).stop( true, true ).fadeIn("fast");
				                $(this).toggleClass('open');
				                $('b', this).toggleClass("caret caret-up");                
				            },
				            function() {
				                $('.dropdown-menu', this).stop( true, true ).fadeOut("fast");
				                $(this).toggleClass('open');
				                $('b', this).toggleClass("caret caret-up");                
				            });

				$(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
				    event.preventDefault();
				    $(this).ekkoLightbox(
				    	{
				    		gallery_parent_selector: '.image-gallery',
						    left_arrow_class: '.icon-angle-left',
						    right_arrow_class: '.icon-angle-right'
				    	});
				}); 

				$('.thumbnails a').hover(
							function() {
								$this = $(this);
								id = $this.attr('id');
								if (matches = id.match(/gallery-thumbnail-(\d+)/)) {
									$gallery = $this.parents('.brand-gallery').first();
									$('.main-image', $gallery).hide();
									$('.additional-images', $gallery).show();
									$('.additional-images img', $gallery).hide();
									$('#gallery-image-'+matches[1]).show();
								}
							},
							function() {
								$this = $(this);
								id = $this.attr('id');
								if (matches = id.match(/gallery-thumbnail-(\d+)/)) {
									$gallery = $this.parents('.brand-gallery').first();
									$('.main-image', $gallery).show();
									$('.additional-images', $gallery).hide();
								}
							});

				$('form.ajax-form').each(function() {
					$form = $(this);

					$form.formValidation();

					if (matches = $form.attr('id').match(/form_(\d+)/)) {
						var id = matches[1];
					} else {
						return;
					}

					$form.attr('enctype', 'application/x-www-form-urlencoded');
					$form.attr('encoding', 'application/x-www-form-urlencoded');
					$form.ajaxForm({
						url: '/gravityformsapi/forms/'+id+'/submissions',
						dataType   : 'json',
					    contentType: 'application/json; charset=UTF-8',
						clearForm: true,
					    beforeSend: function(jqXHR, settings) {
					    	var fields = settings.data.split('&');
						    
						    var result = {};
						    var input_values = {};
						    fields.forEach(function(field) {
						        field = field.split('=');
						        result[field[0].replace('.', '_')] = decodeURIComponent(field[1] || '');
								if (field[0].match(/input_/)) {
									input_values[field[0].replace('.', '_')] = decodeURIComponent(field[1] || '');
								}
						    });
						    result['input_values'] = input_values;

						    settings.data = JSON.stringify(result);
					    },
						beforeSubmit: function(arr, $form, options) {
							$form.addClass('submitting');
							$('.btn-submit', $form).prop('disabled', true);
						},
						complete: function(xhr, status, $form) {
							$form.removeClass('submitting');
							$('.btn-submit', $form).removeClass('disabled').removeAttr('disabled');
							$form.addClass('submit-success');
							$wrapper = $form.parents('.form-wrapper');
							$('.hide-after-submit', $wrapper).hide();
							$('.show-after-submit', $wrapper).show();
						},
						success: function(data, status, xhr, $form) {
							$form.addClass('submit-success');
						}
					});
				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
				anchors = [];
				tooltips = [];
				$('[data-anchor]').each(function() {
					$this = $(this);
					anchors.push($this.data('anchor'));
					tooltips.push($this.data('tooltip'));
				})
				$('#fullpage').fullpage({
					anchors: anchors,
					navigation: true,
					navigationPosition: 'right',
					navigationTooltips: tooltips
				});

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'news': {
			init: function() {
				$('#sidebar').stickyMojo({footerID: '#footer', contentID: '#main'});
			}
		},
		'stores': {
			init: function() {
				$('#sidebar').stickyMojo({footerID: '#footer', contentID: '#main'});

				$('.brand-categories a, .store-brands a').on('click', function() {
					var slug = $(this).attr('href');
					Progression.hide_stores(slug.substr(1));
				});

				if (window.location.hash) {
					Progression.hide_stores(window.location.hash.substr(1));
				}
			}
		},
		'contact_us': {
			init: function() {
				console.log('contact us page');
		        var map = $('#map-canvas');
		        if (map.length > 0 && google) {
		            var coords = map.data('latlong').split(',');
		            if (coords.length == 2) {
		                var myLatLong = new google.maps.LatLng(coords[0], coords[1]); //18.783579,98.990334);

		                var mapOptions = { 
		                  zoom: 14,
		                  center: myLatLong,
		                  mapTypeId: google.maps.MapTypeId.ROADMAP,
		                  scrollwheel: false
		                }

		                var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

		                var marker = new google.maps.Marker({
		                    position: myLatLong,
		                    map: map,
		                    title:"Chiang Mai Rock Climbing Adventures"
		                });
		            }
		        }
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
