(function($) {
	var Progression = {
		'hide_stores': function (slug) {
			$('#store-locations tbody tr').each(function() {
				$row = $(this);
				if ($row.hasClass(slug)) {
					$row.show();
				} else {
					$row.hide();
				}
			});

			slug = '#'+slug;
			$('.brand-categories a').each(function() {
				$a = $(this);
				if ($a.attr('href') == slug) {
					$a.addClass('active');
				} else {
					$a.removeClass('active');
				}
			});
		}
	}

	window.Progression = Progression;
})(jQuery); // Fully reference jQuery after this point.
